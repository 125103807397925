import './index.less'

/**
 * 行业内招投标页面使用
 * 第三方问题查询数据返回重复，让前端限制分页条件。。。。。。。
 * 组件自己看着用吧
 * 已经做了分页的边界情况，基础功能也可供其他页面使用
 */

export default {
    name: 'VPagination',
    props: {
        value: {
            type: Number,
            default: 1
        },
        pageSizeOptions: {
            type: Array,
            default: () => ['10', '20', '30', '40']
        },
        pageSize: {
            type: Number,
            default: 10
        },
        maxShowPage: {
            type: Number,
            default: 5
        },
        total: {
            type: Number,
            default: 100
        }
    },
    data () {
        return {
        }
    },
    computed: {
        pageArr () {
            const { value, maxShowPage, total, pageSize } = this
            const cidx = Math.floor(maxShowPage / 2)
            const maxPage = Math.ceil(total / pageSize)
            const pageMax = Math.min(maxShowPage, maxPage)
            if (value < maxShowPage) {
                return Array.from({ length: pageMax }, (x, i) => i + 1)
            }
            return Array.from({ length: maxShowPage }, (x, i) => {
                if (maxPage - value <= cidx) {
                    return maxPage - maxShowPage + i + 1
                }
                return value + (i - cidx)
            })
        },
        sizeOptions () {
            return this.pageSizeOptions.map(item => ({
                label: `${item}条/页`,
                value: Number(item)
            }))
        }
    },
    render () {
        const { total, pageSize } = this
        const maxPage = Math.ceil(total / pageSize)
        return (
            <div class='v-pagination-wrapper'>
                <div class='pagination-total'>总数 {this.total} 条</div>
                <div class={`${'pagination-prev pagination-item' + (this.value <= 1 ? ' pagination-item-disabled' : '')}`} onClick={() => this.value > 1 && this.$emit('prev')}>
                    <a-icon type="left" />
                </div>
                <div class='pagination-page'>
                    {
                        this.pageArr.map((item) => (
                            <div class={`${'pagination-item' + (this.value === item ? ' pagination-item-active' : '')}`} onClick={() => this.$emit('change', { page: item, pageSize: this.pageSize })} >{item}</div>
                        ))
                    }
                </div>
                <div class={`${'pagination-prev pagination-item' + (this.value === maxPage ? ' pagination-item-disabled' : '')}`} onClick={() => this.value < maxPage && this.$emit('next')}>
                    <a-icon type="right" />
                </div>
                <div class='pagination-size'>
                    <a-select options={this.sizeOptions} value={this.pageSize} class='pagination-size-select' {
                        ...{
                            on: {
                                change: (value) => {
                                    this.$emit('change', {
                                        page: Math.min(this.value, Math.ceil(total / value)),
                                        pageSize: value
                                    })
                                }
                            }
                        }
                    } ></a-select>
                </div>
            </div>
        )
    }

}
